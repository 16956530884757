import React, { Component, Fragment } from 'react'
import {
  // MDBContainer,
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBInput,
  MDBModalFooter,
  MDBIcon,
} from 'mdbreact'
import ContactPage from '../pages/contact'

class ModalPage extends Component {
  constructor() {
    super()
    this.state = {
      modal4: false,
      name: '',
      email: '',
      message: '',
    }
  }

  toggle = nr => () => {
    let modalNumber = 'modal' + nr
    this.setState({
      [modalNumber]: !this.state[modalNumber],
    })
  }
  handleInput = e => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  sendForm = () => {
    // console.log(
    //   JSON.stringify({
    //     email: this.state.email,
    //     name: this.state.name,
    //     message: this.state.message,
    //   })
    // )
    // Send to AWS Lambda serverless function that calls Amazon SES https://us-west-2.console.aws.amazon.com/lambda/home?region=us-west-2#/functions/contact-form-api-dev-send?tab=graph

    fetch(
      'https://i0kfyypmec.execute-api.us-west-2.amazonaws.com/dev/email/send',
      {
        method: 'POST',
        body: JSON.stringify({
          email: this.state.email,
          name: this.state.name,
          content: this.state.message,
        }),
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )
      .then(res => res.json())
      .catch(err => console.log(err))
  }

  render() {
    return (
      // <MDBContainer>
      <Fragment>
        <MDBBtn color="orange accent-4" rounded onClick={this.toggle(4)}>
          Schedule a Demo
        </MDBBtn>
        <MDBModal
          backdrop={true}
          isOpen={this.state.modal4}
          toggle={this.toggle(4)}
          size="lg"
        >
          <ContactPage></ContactPage>
          {/* <MDBModalHeader
            className="text-center"
            titleClass="h2-responsive"
            toggle={this.toggle(4)}
          >
            Write to us
          </MDBModalHeader>
          <MDBModalBody>
            For more details and a demo of what HearRo can do for you
            <form className="mx-3 grey-text">
              <MDBInput
                label="Your name"
                icon="user"
                group
                type="text"
                validate
                error="wrong"
                success="right"
                name="name"
                value={this.state.name}
                onInput={this.handleInput}
              />
              <MDBInput
                label="Your email"
                icon="envelope"
                group
                type="email"
                validate
                error="wrong"
                success="right"
                name="email"
                value={this.state.email}
                onInput={this.handleInput}
              />
              {/* <MDBInput label="Your Subject" icon="tag" group type="text" /> */}
          {/* <MDBInput
                type="textarea"
                rows="2"
                label="Your message"
                icon="edit"
                name="message"
                value={this.state.message}
                onInput={this.handleInput}
              />
            </form>
          </MDBModalBody>
          <MDBModalFooter className="justify-content-center">
            <MDBBtn color="orange accent-2" onClick={this.sendForm}>
              Send
              <MDBIcon far icon="paper-plane" className="ml-2" />
            </MDBBtn>
          </MDBModalFooter> */}
        </MDBModal>
      </Fragment>
    )
  }
}

export default ModalPage
