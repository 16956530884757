import React, { Component } from 'react'
import Layout from '../components/layout'
import '../assets/scss/mdb.scss'
import {
  MDBFreeBird,
  MDBInput,
  MDBView,
  MDBMask,
  MDBCol,
  MDBRow,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBBtn,
  MDBContainer,
  MDBEdgeHeader,
  MDBAnimation,
} from 'mdbreact'
// import '../assets/css/contactform.css'
import { Link } from 'gatsby'

class ContactPage extends Component {
  constructor() {
    super()
    // this.handleSubmitClick = this.handleSubmitClick.bind(this);
    // this.handleCloseClick = this.handleCloseClick.bind(this);
    this.state = {
      name: '',
      email: '',
      message: '',
      reducing: false,
      integration: false,
      improving: false,
      other: false,
      responseMessage: '',
      isSubmitted: false,
      isClicked: false,
    }
  }

  handleInput = e => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  handleClick = e => {
    this.setState({ isClicked: true })
  }

  // handleInput(event) {
  //   const target = event.target
  //   const value = target.type === 'checkbox' ? target.checked : target.value
  //   const name = target.name

  //   this.setState({
  //     [name]: value,
  //   })
  // }

  sendForm = () => {
    this.setState({ isSubmitted: true })
    this.setState({
      responseMessage:
        'Thanks, we received your request and will get in touch with you soon',
    })
    console.log(
      JSON.stringify({
        email: this.state.email,
        name: this.state.name,
        message: this.state.message,
        reducing: this.state.reducing,
        integrating: this.state.integrating,
        improving: this.state.improving,
        other: this.state.other,
      })
    )
    // Send to AWS Lambda serverless function that calls Amazon SES https://us-west-2.console.aws.amazon.com/lambda/home?region=us-west-2#/functions/contact-form-api-dev-send?tab=graph
    fetch(
      'https://i0kfyypmec.execute-api.us-west-2.amazonaws.com/dev/email/send',
      {
        method: 'POST',
        body: JSON.stringify({
          email: this.state.email,
          name: this.state.name,
          content: this.state.message,
        }),
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )
      .then(res => res.json())

      .catch(err => console.log(err))
  }

  render() {
    const isSubmitted = this.state.isSubmitted
    let button

    if (isSubmitted) {
      button = (
        <Link to="/">
          <MDBBtn
            color="blue accent-2"
            className="text-xs-left"
            onClick={this.isClicked}
          >
            Close
          </MDBBtn>
        </Link>
      )
    } else {
      button = (
        <MDBBtn
          color="orange accent-2"
          className="text-xs-left"
          onClick={this.sendForm}
        >
          Submit
        </MDBBtn>
      )
    }
    return (
      <Layout>
        <div id="contactformpage">
          <MDBView>
            <MDBMask
              className="d-flex justify-content-center align-items-center"
              overlay="indigo-strong"
            >
              <MDBContainer>
                <MDBRow>
                  <MDBCol
                    md="12"
                    xl="5"
                    className="mb-4 white-text text-center text-md-left"
                  >
                    <MDBAnimation type="fadeInLeft" delay=".3s">
                      <h1 className="h1-responsive display-4">
                        Deliver better customer service.
                      </h1>
                      <hr className="hr-light" />
                      <h6 className="mb-4">
                        See for yourself how HearRo can help you deliver better
                        customer service.
                      </h6>
                    </MDBAnimation>
                  </MDBCol>
                  <MDBCol md="12" xl="5" className="mb-4">
                    <MDBAnimation type="fadeInRight" delay=".3s">
                      <MDBCard className="dark-grey-text">
                        <MDBCardBody>
                          {/* <MDBCardTitle>I'm interested in ...</MDBCardTitle>
                          <MDBInput
                            label="Reducing Call Time"
                            filled
                            type="checkbox"
                            id="checkbox1"
                            name="reducing"
                            checked={this.state.reducing}
                            onChange={this.handleInput}
                          />
                          <MDBInput
                            label="Call Center Integration"
                            filled
                            type="checkbox"
                            id="checkbox2"
                            name="integration"
                            checked={this.state.integration}
                            onChange={this.handleInput}
                          />
                          <MDBInput
                            label="Improving Customer Service"
                            filled
                            type="checkbox"
                            id="checkbox3"
                            name="improving"
                            checked={this.state.improving}
                            onChange={this.handleInput}
                          />
                          <MDBInput
                            label="Other"
                            filled
                            type="checkbox"
                            id="checkbox4"
                            name="other"
                            checked={this.state.other}
                            onChange={this.handleInput}
                          /> */}
                          <form>
                            <MDBInput
                              label="Your name"
                              icon="user"
                              group
                              type="text"
                              validate
                              error="wrong"
                              success="right"
                              name="name"
                              value={this.state.name}
                              onInput={this.handleInput}
                            />
                            <MDBInput
                              label="Your email"
                              icon="envelope"
                              group
                              type="email"
                              validate
                              error="wrong"
                              success="right"
                              name="email"
                              value={this.state.email}
                              onInput={this.handleInput}
                            />
                            <MDBInput
                              type="textarea"
                              rows="2"
                              label="Your message"
                              icon="pencil"
                              name="message"
                              value={this.state.message}
                              onInput={this.handleInput}
                            />
                            {/* <MDBBtn
                              color="orange accent-2"
                              className="text-xs-left"
                              onClick={this.sendForm}
                            >
                              Submit
                            </MDBBtn> */}
                            <div>
                              {this.state.responseMessage}
                              <div>{button}</div>
                            </div>
                          </form>
                        </MDBCardBody>
                      </MDBCard>
                    </MDBAnimation>
                  </MDBCol>
                </MDBRow>
              </MDBContainer>
            </MDBMask>
          </MDBView>
        </div>
      </Layout>
    )
  }
}

export default ContactPage
