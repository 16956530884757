import React from 'react'
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  // MDBCardImage,
  MDBCardTitle,
} from 'mdbreact'
import ContactModal from '../components/contactmodal'
import image from '../images/mockup.webp'
import { ReactComponent as Schedule } from '../images/hand_with_pen_mark_calendar.svg'
import { ReactComponent as Connect } from '../images/connect_w_customers.svg'
import { ReactComponent as Hands } from '../images/hands_holding_hearro.svg'

const StepsSection = () => {
  return (
    <section id="cardSection">
      <h2 className="h1-responsive text-center mb-5">
        Schedule a call to see if HearRo is a fit for you
      </h2>
      <MDBRow className="m-0" center>
        <MDBCol sm="4" xs="12">
          <MDBCard>
            <h2 className="text-center">Step 1</h2>
            <Schedule></Schedule>
            {/* <MDBCardImage className="img-fluid" src={image} alt="" waves /> */}
            <MDBCardBody>
              <MDBCardTitle className="text-center">
                Schedule a Demo
                <pre> </pre>
              </MDBCardTitle>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
        <MDBCol sm="4" xs="12">
          <MDBCard>
            <h2 className="text-center">Step 2</h2>
            <Connect></Connect>
            {/* <MDBCardImage className="img-fluid" src={image} alt="" waves /> */}
            <MDBCardBody>
              <MDBCardTitle className="text-center">
                Connect with Customers via HearRo Platform
              </MDBCardTitle>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
        <MDBCol sm="4" xs="12" >
          <MDBCard>
            <h2 className="text-center">Step 3</h2>
            <Hands></Hands>
            <MDBCardBody>
              <MDBCardTitle className="text-center">
                Deliver Better Customer Service
              </MDBCardTitle>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
      </MDBRow>
      <MDBContainer tag="section" className="text-center my-5">
        <h2 className="h1-responsive  text-center my-5">
          Create An Effortless Connection With Your Customers.
        </h2>
        <ContactModal />
      </MDBContainer>
    </section>
  )
}

export default StepsSection
